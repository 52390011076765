

































import { Component, Vue } from 'vue-property-decorator'
import { uiStore } from '@/store'
import { preventHtmlTags } from '@/utils'

import { format } from 'quasar'

import i18n from '@/i18n'

const { capitalize } = format

@Component({
  name: 'UnblockMicModal',
  components: {
    Firefox: () => import('./UnblockMicComponents/FirefoxMic.vue'),
    Opera: () => import('./UnblockMicComponents/OperaMic.vue'),
    Edge: () => import('./UnblockMicComponents/EdgeMic.vue'),
    Safari: () => import('./UnblockMicComponents/SafariMic.vue'),
    Yandex: () => import('./UnblockMicComponents/YandexMic.vue'),
    Chromium: () => import('./UnblockMicComponents/ChromiumMic.vue'),
    Windows: () => import('./UnblockMicComponents/WindowsMic.vue'),
    Macos: () => import('./UnblockMicComponents/MacosMic.vue'),
  },
})
export default class UnblockMicModal extends Vue {
  host = '';
  isYandex = false;
  isMac = false;
  isWin = false;
  lang: 'ru' | 'en' | string = 'ru';

  created () {
    this.host =
      window.location.protocol +
      '//' +
      (window.FEATURES.landing_url ?? location.host)
    this.host = preventHtmlTags(this.host)
    this.lang = i18n.locale ?? 'ru'
  }

  // NEED to use mounted hook because of navigator spawn specific
  mounted () {
    this.isMac = this.$q.platform.is.electron && this.$q.platform.is.mac
    this.isWin = this.$q.platform.is.electron && this.$q.platform.is.win
    this.isYandex = navigator.userAgent.toLowerCase().search(/yabrowser/) > 0
  }

  get componentName (): string {
    // must be first in if because of computed specific
    if (this.isYandex) return 'Yandex'
    if (this.isMac) return 'Macos'
    if (this.isWin) return 'Windows'
    if (this.$q.platform.is.firefox) return 'Firefox'
    if (this.$q.platform.is.opera) return 'Opera'
    if (this.$q.platform.is.edge) return 'Edge'
    if (this.$q.platform.is.safari) return 'Safari'
    // other chromium browsers
    // must be last
    return 'Chromium'
  }

  get closeText (): string {
    return capitalize(this.$t('glossary.close').toString())
  }

  hideModal (): void {
    uiStore.actions.hideModal()
  }
}
